import React from 'react'
import Hero from '../../components/common/Hero'
import TopHeader from '../../components/merchantLanding/header/TopHeader'
import Footer from '../../components/footer/Footer'
import './about.scss'
import AboutItem from './AboutItem'
import Faq from '../../components/faq/Faq'
function About() {
  return (
    <div className='services'>
        <TopHeader />

        <Hero 
        subtitle='about us'
        title='We Save The Environment.'
        desc='It represents a clean, green source of energy. Solar power is a great way to reduce your carbon footprint.'
        img='/images/hero4.jpg'
        />
        <section className='about'>
            <div className='wrapper aboutflex'>

                <div className='about_sec1_top'>
                    <h4 className='about_sec1'>ABOUT COMPANY</h4>
                    <h2 className='about_reason'>Reasons Why Choose Us</h2>
                    <p className='about_desc'>We believe we can make a difference to this world, to this very earth on which we live. We have been destroying the forests for whatever reasons for many years. </p>
                </div>

                <div className='about_list'>
        
                    <AboutItem 
                    title='Renewable'
                    desc='Sustainable sources, naturally replenished, play a crucial role in reducing emissions and decreasing reliance on fossil fuels.'
                    />
                    <AboutItem 
                    title='Emergency Backup'
                    desc='Experience the peace of mind that comes with reliable power supply through our comprehensive solar energy solutions.'
                    />
                    <AboutItem 
                    title='Global Expertise'
                    desc='Our global expertise in solar energy is highly esteemed and widely acknowledged across various industries and regions.'
                    />
                    <AboutItem 
                    title='Demand Response'
                    desc='We pride ourselves on our quick response to demand, leveraging our expertise to efficiently address energy needs.'
                    />
                </div>
            </div>
        </section>

        <section className='sec4'>
        <div className='sec4_container'>

        <div className='wrapper markflex'>
            <div className='about_left'>
                <div className='aboutmarker'>
                    <img src='/images/aboutMark.svg' alt=''/>
                    <p className='about_com'>ABOUT COMPANY</p>
                </div>
                <h1 className='about_marker_title'>We’re the #1 Solar Energy Provider!</h1>
                <p className='about_marker_desc'>We're dedicated to making a difference in our world, on the very earth we call home. For years, forests have been depleted for various reasons. It's evident that adopting solar technology for lighting highway rest facilities not only saves energy but also promotes environmental conservation.</p>

                <div className='about_count'>
                    <div className='about_count_left'>
                        <h4 className='about_count_num'>96k</h4>
                        <p className='about_count_text'>Sucessful Projects</p>
                        <p className='about_count_desc'>The most comprehensive complete system warranty in the solar industry. </p>
                    </div>
                    <div className='about_count_right'>
                    <h4 className='about_count_num'>550k+</h4>
                        <p className='about_count_text'>Happy Customers</p>
                        <p className='about_count_desc'>The most comprehensive complete system warranty in the solar industry. </p>
                    </div>
                </div>
            </div>

            <img src='/images/aboutImg.jpg' alt=''/>
            
        </div>
        </div>
            <img className='sec4_img' src='/images/cta.jpg' alt='' />
      </section>

      <section className='about_sec3'>
        <div className='about_team'>
            <div className='about_team_maker'>
                <img src='/images/aboutExpert.svg' alt=''/>
                <p className='about_creative'>Creative Nerds</p>
            </div>
            <h4 className='about_meet_team'>Meet Our Team</h4>
            <div className='name_about'>
                <img src='/images/team1.svg' alt=''/>
                <img src='/images/team2.svg' alt=''/>
                <img src='/images/team3.svg' alt=''/>
            </div>
        </div>
      </section>

      <section className='sec6 faq_bg'>
        <div className='wrapper dflex mtop'>
          <div className='sec6_left'>
            <div className='sec6_faq'>
                <h6 className='sec6_subtitle'>ABOUT COMPANY</h6>
            </div>
            <h4 className='sec6_title'>Frequently Asked <br/> Questions</h4>
            <p className='sec6_desc'>The most comprehensive complete-system warranty in the solar industry. Hassle-free replacement, repair or reimbursement.</p>
            <p className='sec6_desc'> Any Question? <span>support@truewishenergy.com</span> </p>
          </div>

          {/* <div className='sec6_right'>
            <div className='sec6_pay'>
              <h6 className='sec6_pay_title'>Can I pay with a credit card?</h6>
              <img src='/images/minus.svg' alt=''/>
            </div>
            <p className='sec6_right_desc'>This includes the frame which provides structural rigidity to the panel, the glass which protects the top side of the panel,  the EVA which is the glue that binds all the components of the panel, the cells where sunlight is converted to electricity and finally the junction box and connectors which transfers energy from one panel to the next.</p>
            <div className='sec6_pay'>
              <h6 className='sec6_pay_title'>Technical team of enthusiastic?</h6>
              <img src='/images/chevron-right.svg' alt=''/>
            </div>
            <div className='sec6_pay' style={{marginTop:'24px'}}>
              <h6 className='sec6_pay_title'>Do you give guarantee and after sales service?</h6>
              <img src='/images/chevron-right.svg' alt=''/>
            </div>
          </div> */}

          <div className='sec6_right'>
            <Faq/>
          </div>
        </div>
      </section>

        <Footer />
    </div>
  )
}

export default About