import React from 'react'
import './faq.scss'
function Faq() {
  return (
    <div className="faq-list">
            <ul>
              <li data-aos="fade-up">
                {/* <i className="bx bx-help-circle icon-help"></i>{' '} */}
                <a
                  data-bs-toggle="collapse"
                  className="collapsed"
                  href="#faq-list-1"
                  role="button" 
                  aria-expanded="false"
                  aria-controls="faq-list-1"
                >
                  ⁠Do we buy used batteries?{' '}
                  <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-chevron-up icon-close"></i>
                </a>
                <div id="faq-list-1" className="collapse" data-bs-parent=".faq-list">
                  <p className='answer_item'>
                  No we don’t 
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="100">
                {/* <i className="bx bx-help-circle icon-help"></i>{' '} */}
                <a
                  data-bs-toggle="collapse"
                  className="collapsed"
                  href="#faq-list-2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="faq-list-2"
                >
                ⁠⁠Does Truewish offer interstate  delivery?{' '}
                  <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-chevron-up icon-close"></i>
                </a>
                <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                  <p className='answer_item'>
                  - specific state like Ibadan, Ogun state.
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="200">
                {/* <i className="bx bx-help-circle icon-help"></i>{' '} */}
                <a
                  data-bs-toggle="collapse"
                  className="collapsed"
                  href="#faq-list-3"
                  role="button"
                  aria-expanded="false"
                  aria-controls="faq-list-3"
                >
                   ⁠⁠Does Truewish offer Installment service?{' '}
                  <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-chevron-up icon-close"></i>
                </a>
                <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                  <p className='answer_item'>
                   - yes we do, with a 20% initial deposit and spread the rest within a year
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="300">
                {/* <i className="bx bx-help-circle icon-help"></i>{' '} */}
                <a
                  data-bs-toggle="collapse"
                  className="collapsed"
                  href="#faq-list-4"
                  role="button"
                  aria-expanded="false"
                  aria-controls="faq-list-4"
                >
                what are the types of batteries we sell?{' '}
                  <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-chevron-up icon-close"></i>
                </a>
                <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                  <p className='answer_item'>
                  - Wet cell Batteries
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="400">
                {/* <i className="bx bx-help-circle icon-help"></i>{' '} */}
                <a
                  data-bs-toggle="collapse"
                  className="collapsed"
                  href="#faq-list-5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="faq-list-5"
                >
                  Does Truewish offer swap?{' '}
                  <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-chevron-up icon-close"></i>
                </a>
                <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                  <p  className='answer_item'>
                  - No we don’t.
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="400">
                {/* <i className="bx bx-help-circle icon-help"></i>{' '} */}
                <a
                  data-bs-toggle="collapse"
                  className="collapsed"
                  href="#faq-list-6"
                  role="button"
                  aria-expanded="false"
                  aria-controls="faq-list-6"
                >
                  How many months warranty do we offer?{' '}
                  <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-chevron-up icon-close"></i>
                </a>
                <div id="faq-list-6" className="collapse" data-bs-parent=".faq-list">
                  <p className='answer_item' >
                  - 18 months
                  </p>
                </div>
              </li>
            </ul>
          </div>
  )
}

export default Faq
